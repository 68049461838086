exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articulo-tsx": () => import("./../../../src/pages/articulo.tsx" /* webpackChunkName: "component---src-pages-articulo-tsx" */),
  "component---src-pages-articulos-tsx": () => import("./../../../src/pages/articulos.tsx" /* webpackChunkName: "component---src-pages-articulos-tsx" */),
  "component---src-pages-aviso-tsx": () => import("./../../../src/pages/aviso.tsx" /* webpackChunkName: "component---src-pages-aviso-tsx" */),
  "component---src-pages-banco-noticias-tsx": () => import("./../../../src/pages/banco_noticias.tsx" /* webpackChunkName: "component---src-pages-banco-noticias-tsx" */),
  "component---src-pages-biblioteca-tsx": () => import("./../../../src/pages/biblioteca.tsx" /* webpackChunkName: "component---src-pages-biblioteca-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-donar-tsx": () => import("./../../../src/pages/donar.tsx" /* webpackChunkName: "component---src-pages-donar-tsx" */),
  "component---src-pages-equipo-tsx": () => import("./../../../src/pages/equipo.tsx" /* webpackChunkName: "component---src-pages-equipo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inversion-social-tsx": () => import("./../../../src/pages/inversion-social.tsx" /* webpackChunkName: "component---src-pages-inversion-social-tsx" */),
  "component---src-pages-modelo-tsx": () => import("./../../../src/pages/modelo.tsx" /* webpackChunkName: "component---src-pages-modelo-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes_somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-vacantes-tsx": () => import("./../../../src/pages/vacantes.tsx" /* webpackChunkName: "component---src-pages-vacantes-tsx" */)
}

